// Gatsby requirements
import React from "react"
import { Link } from 'gatsby'

// Components
import Layout from '../components/Layout/Layout'
import Seo from '../components/Layout/Seo'


const NotFoundPage = () => {
  return (
    <Layout>

      <Seo
          title="404 – Page Not Found"
          description="Page not found"
      />

      <main style={{textAlign: 'center', margin: '5em 1em'}}>
        <h1>Page not found</h1>
        <p style={{maxWidth: '20em', margin: '-1em auto 1em auto'}}>The page you are looking for might have moved elsewhere or the address was wrong.</p>
        <Link to="/" className="button">Back to homepage</Link>
      </main>

    </Layout>
  )
}

export default NotFoundPage
